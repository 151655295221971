/* @font-face {
  font-family: 'Suisse Intl';
  src: url('/public/fonts/fonnts.com-Suisse_Intl_Regular.ttf') format('truetype');
} */

@font-face {
  font-family: 'Suisse Intl';
  src: url('/public/fonts/SuisseIntl-Book.otf') format('opentype');
  font-weight: 350;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/public/fonts/SuisseIntl-Book.otf') format('opentype');
  font-weight: 400;
}


@font-face {
  font-family: 'Suisse Intl';
  src: url('/public/fonts/SuisseIntl-Book.otf') format('opentype');
  font-weight: 450;
} 

@font-face {
  font-family: 'Suisse Intl';
  src: url('/public/fonts/SuisseIntl-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/public/fonts/SuisseIntl-SemiBold.otf') format('opentype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: 'SuisseIntl', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiTypography-root {
  font-family: 'SuisseIntl', sans-serif;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}